(function($) {
    'use strict';

    $(document).foundation();

    /**
     * Register click events on sub navigation toggles
     */

    $('.c-offcanvas__menu').find('.menu__item--has-children').prepend('<span class="menu__item--toggle"></span>');
    $('.menu__item--toggle').on('click', function() {
        $(this).parent().toggleClass('is-active');
    });




    /**
     * Add class to Flickity element during scrolling
     */
    var carouselEl = $('[data-flickity-options]');

    carouselEl.on('scroll.flickity', function () {
        $(this).addClass('is-scrolling');
    });

    carouselEl.on('settle.flickity', function () {
        $(this).removeClass('is-scrolling');
    });




    /**
     * Trigger actions when the page loads
     */
    $(function() {
        
        $('body').addClass('page-is-ready');

        setTimeout(function () {

            $('.js-grid-container').isotope('layout');

            $(window).trigger('resize');

        }, 500 );

        setTimeout(function () {
            
            $('[data-flickity-options]').removeClass('is-scrolling');

        }, 2000 );
        
    });



    
    /**
     * Select hidden first tab when heading is clicked
     */
    $('.c-tabbed-content__heading').on('click', function () {
        $(this).parent().find('li:first-of-type > a').click();
    });

    /**
    *   Show Spa depending on page (for mobile and tablet) 
    */

    if (window.location.href.indexOf("/alexander-house/") > -1) {
        $('<style>.c-nav-header__item--book-spa { display: inline-block }</style>').appendTo('body');
    }

    else if (window.location.href.indexOf("/rowhill-grange/") > -1){
        $('<style>.c-nav-header__item--book-spa { display: inline-block }</style>').appendTo('body');
    }

    // For Browser hacks
    // 
    var isSafari = /constructor/i.test(window.HTMLElement);

    if(isSafari){
        $('body').addClass('browser-safari');
    }

    var isIE = window.navigator.msPointerEnabled;
    var isIE = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
    
    if(isIE){
        $('body').addClass('browser-ie');
    }


     /**
      *   Header Book Now button drop down click event on small viewports - prevent the default behaviour and allowing the    *   dropdown-content to appear. 
    */

    $('.c-page-header__book').on('click', function() {

        $('.dropdown-content').toggleClass('is-active'); 
        
    });

    /**
     * Is active class added to radio buttons
     */

    $('.c-grid-filter__radio-button').on('click', function() {
        $(".c-grid-filter__radio-button").removeClass('is-active'); 
        $(this).addClass('is-active'); 
    });

    $(document).ready(function(){
        $('.c-grid-filter__radio-button:nth-of-type(1)').addClass('is-active');
    });



})(jQuery);